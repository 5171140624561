import {apiURL,admin} from '@/../config';
import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_LOGOUT, USER_ADD_SESSION, USER_VIEW_SESSION, USER_UPDATE_PROFILE, USER_DOWNLOADED_FILE } from "../actions/user";
import Vue from "vue";
import { AUTH_LOGOUT } from "../actions/auth";
import axios from "axios";

const state = { status: "", profile: {}, notifications: [] }

const getters = {
  getProfile: state => state.profile,
  getUserId: state => state.profile._id,
  getFullName: state => state.profile.firstname + " " + state.profile.lastname,
  getFirstname: state => state.profile.firstname,
  getLastname: state => state.profile.lastname,
  getPicture: state => state.profile.picture,
  getSubscriberId: state => state.profile.subscription.subscriberId,
  getEmail: state => state.profile.email,
  getUserSessionsList: state=>state.profile.history.sessions_done,
  getUserAssessments: state=>state.profile.assessments,
  isProfileLoaded: state => !!state.profile.email,
  isCustomer: state => state.profile.permission === 'Customer' || state.profile.admin,
  isSubscriber: state => state.profile.permission === 'Subscriber' || state.profile.permission === 'Premium',
  isUnsubscriber: state => state.profile.permission === 'Unsubscriber',
  isPremium: state => state.profile.permission === 'Premium',
  isAdmin: state => state.profile.admin,
  hasCollectives: state => state.profile.collectives,
  hasPassword : state => { return !!state.profile.activationToken },
  getActivationToken : state => { return state.profile && state.profile.activationToken ? state.profile.activationToken : '' },
  getNotifications : state => {
    const notifs = []
    if(!admin && state.profile && !state.profile.infos_replied ){
      notifs.push({
        text: `Mettez à jour vos informations`,
        icon: 'mdi-account-alert',
        type: 'error',
        cb: () => {
          this.$router.push('/user/me')
        }
      })
    }
    return [...notifs, ...state.notifications]
  }
}

const actions = {
  [USER_REQUEST]: async ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    try {
      const userRequest = await axios.post(apiURL + 'auth/me')
      commit(USER_SUCCESS, userRequest.data.profile)
    }catch(err) {
      commit(USER_ERROR);
      commit(USER_LOGOUT);
      dispatch(AUTH_LOGOUT);
    }
  },
  [USER_ADD_SESSION]: async ({ commit },payload) => {
    try{
      const updatedProfile = await axios.post(apiURL + 'auth/me/log-session/finished',payload);
      commit(USER_SUCCESS, updatedProfile.data.profile);
    }catch(err){
      commit(USER_ERROR);
    }
  },
  [USER_VIEW_SESSION]: async ({ commit },payload) => {
    try{
      const updatedProfile = await axios.post(apiURL + 'auth/me/log-session/viewed',payload);
      commit(USER_SUCCESS, updatedProfile.data.profile);
    }catch(err){
      commit(USER_ERROR);
    }
  },
  [USER_DOWNLOADED_FILE]: async ({ commit },payload) => {
    try{
      const updatedProfile = await axios.post(apiURL+'auth/me/downloaded/file/'+payload.id, {}, { params: { file: payload.iscv?'cv':payload.iscl?'coverletter':''}})
      commit(USER_SUCCESS, updatedProfile.data.profile);
    }catch(err){
      commit(USER_ERROR);
    }
  },
  [USER_UPDATE_PROFILE]: async ({ commit },payload) => {
    try{
      commit(USER_SUCCESS, payload.user);
    }catch(err){
      commit(USER_ERROR);
    }
  },
  updateMe: async({commit,getters},payload)=>{
    await commit(USER_REQUEST);
    try{
      const user = {
        firstname: payload.user.firstname,
        lastname: payload.user.lastname,
        _id: getters.getProfile._id,
        email: getters.getEmail,
        searchContract: {
          state: payload.user.searchContract.state,
          contractTypes: payload.user.searchContract.contractTypes
        },
        contract: {
          contractType: payload.user.contract.contractType,
          begin: payload.user.contract.begin,
          end: payload.user.contract.end
        }
      }
      const updatedProfile = await axios.post(apiURL + 'auth/me/update',{ user, recaptcha:payload.recaptcha })
      commit(USER_SUCCESS, updatedProfile.data.queryRes ?? updatedProfile.data);
      return true
    }catch(err){
      commit(USER_ERROR);
      throw err;
    }
  },
  post_register_infos: async({commit},payload)=>{
    await commit(USER_REQUEST);
    try{
      const updatedProfile = await axios.post(apiURL + 'auth/me/personnal-infos',{ register_infos: payload.register_infos, recaptcha: payload.recaptcha })
      commit(USER_SUCCESS, updatedProfile.data.queryRes ?? updatedProfile.data);
      return true
    }catch(err){
      commit(USER_ERROR);
      throw err;
    }
  },
  set_password : async ( { commit, getters }, payload ) => {
    await axios.post(`${apiURL}public/activate-account/${getters.getActivationToken}`,{password: payload.user.password, recaptcha : payload.recaptcha })
    commit('activation_done')
    return 
  },
  remove_activation_token : async ( { commit } ) => {
    commit('activation_done')
    return
  }
}

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
    return;
  },
  [USER_SUCCESS]: (state, profile) => {
    state.status = "success";
    Vue.set(state, "profile", profile);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [USER_LOGOUT]: state => {
    state.profile = {};
  },
  activation_done: state => {
    state.profile.activationToken = ''
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
