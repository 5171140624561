import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import { jobEden, myjobacademy, stats, schoolApp } from '@/../config'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  store.dispatch('set_pending', to.fullPath)
  next('/login')
}

const ifAdmin =  function(to,from,next){
  if(store.getters.isAuthenticated && !store.getters.isProfileLoaded){
    setTimeout(() => {
      return ifAdmin(to,from,next)
    }, 50)
  }else{
    if(store.getters.isAuthenticated && store.getters.isAdmin){
      next()
      return
    }else if(store.getters.isAuthenticated){
      store.dispatch('AUTH_LOGOUT')
    }
    next('/login')
  }
}

const kick = (to, from, next) => next('/')

function meta(title){
  const hyphen = title ? ' - ':''
  return{
    title: `${title}${hyphen}MyJobAcademy ADMIN`,
  }
}

const routes = [
  //regular routes
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: meta('Accueil')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: meta('Connexion')
  },
  //admin routes
    //courses
  {
    path: '/admin/courses',
    name: 'All Courses',
    component: () => import('../views/courses/All.vue'),
    beforeEnter: myjobacademy ? ifAuthenticated : kick,
    meta: meta('Les formations')
  },
  {
    path: '/admin/courses/new',
    name: 'New Course',
    component: () => import('../views/courses/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Nouvelle formation')
  },
  {
    path: '/admin/courses/:id/edit',
    name: 'Edit Course',
    component: () => import('../views/courses/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification de formation')
  },
    //journeys
  {
    path: '/admin/journeys',
    name: 'All Journeys',
    component: () => import('../views/courses/All.vue'),
    beforeEnter: myjobacademy ? ifAuthenticated : kick,
    props: {isJourney: true},
    meta: meta('Les parcours')
  },
  {
    path: '/admin/journeys/new',
    name: 'New Journey',
    component: () => import('../views/courses/New.vue'),
    props: {isJourney: true},
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Nouveau parcours')
  },
  {
    path: '/admin/journeys/:id/edit',
    name: 'Edit Journey',
    component: () => import('../views/courses/New.vue'),
    props: {isJourney: true},
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification de parcours')
  },
    //sessions
  {
    path: '/admin/sessions',
    name: 'All Session',
    component: () => import('../views/sessions/All.vue'),
    beforeEnter: myjobacademy ? ifAuthenticated : kick,
    meta: meta('Les ateliers')
  },
  {
    path: '/admin/sessions/new',
    name: 'New Session',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Nouvel atelier')
  },
  {
    path: '/admin/courses/:courseId/sessions/new',
    name: 'New Session in course',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Nouvel atelier')
  },
  {
    path: '/admin/sessions/:sessionId/edit',
    name: 'Update Session',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification d’atelier')
  },
  {
    path: '/admin/courses/:courseId/sessions/:sessionId/edit',
    name: 'Update Session in course',
    component: () => import('../views/sessions/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification d’atelier')
  },
  {
    path: '/admin/journeys/:courseId/sessions/:sessionId/edit',
    name: 'Update Session in journey',
    component: () => import('../views/sessions/New.vue'),
    props: {isJourney: true},
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification d’atelier')
  },
  {
    path: '/admin/localAddSession',
    name: 'Local add Session',
    component: () => import('../views/sessions/AddLocal.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick
  },
    //users
  {
    path: '/admin/dashboard/users',
    name: 'Dashboard Users',
    component: () => import('../views/users/Dashboard.vue'),
    beforeEnter: myjobacademy && schoolApp ? ifAdmin : kick,
    meta: meta('Tableau de bord admin')
  },
  {
    path: '/admin/users',
    name: 'Show Users',
    component: () => import('../views/users/All.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Les utilisateurs')
  },
  {
    path: '/admin/users/new',
    name: 'New User',
    component: () => import('../views/users/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Nouvel utilisateur')
  },
  {
    path: '/admin/users/:id',
    name: 'One User',
    component: () => import('../views/users/One.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Infos utilisateur')
  },
  {
    path: '/admin/users/:id/edit',
    name: 'Edit User',
    component: () => import('../views/users/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification d’utilisateur')
  },
    //logbook
  {
    path: '/admin/job-applications/:userId',
    name: 'My Logbook',
    component: () => import('../assets/jobtimise-common/views/myjobacademy/candidatures/Logbook.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Candidatures utilisateur')
  },
  {
    path: '/admin/job-applications/:userId/new',
    name: 'New Candidature',
    component: () => import('../assets/jobtimise-common/views/myjobacademy/candidatures/Candidature.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Nouvelle Candidature utilisateur')
  },
  {
    path: '/admin/job-applications/:userId/edit/:candidatureId',
    name: 'One Candidature',
    component: () => import('../assets/jobtimise-common/views/myjobacademy/candidatures/Candidature.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Candidature utilisateur')
  },
    //votes
  {
    path: '/admin/votes/sessions',
    name: 'Sessions votes',
    component: () => import('../views/sessions/Votes.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Votes des ateliers')
  },
    //emails
  {
    path: '/admin/emails',
    name: 'Send emails',
    component: () => import('../views/Emails.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Envoi d’emails')
  },
    //curriculums
  {
    path: '/admin/curriculums',
    name: 'Show curriculums',
    component: () => import('../views/curriculums/All.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Les cursus')
  },
    //stats
  {
    path: '/admin/stats/myjobacademy',
    name: 'View statistics for myjobacademy',
    component: () => import('../views/Statistics.vue'),
    beforeEnter: (myjobacademy && stats) ? ifAdmin : kick,
    meta: meta('Les statistics')
  },
    //coaches
  {
    path: '/admin/coaches',
    name: 'Show Coaches',
    component: () => import('../views/coaches/All.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Les coaches')
  },
  {
    path: '/admin/coaches/new',
    name: 'New Coach',
    component: () => import('../views/coaches/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Nouveau coach')
  },
  {
    path: '/admin/coaches/:coachId/edit',
    name: 'Edit Coach',
    component: () => import('../views/coaches/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification de coach')
  },
    //collectives
  {
    path: '/admin/collectives',
    name: 'Show Collectives',
    component: () => import('../views/collectives/All.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Les ateliers collectifs')
  },
  {
    path: '/admin/collectives/new',
    name: 'New Collective',
    component: () => import('../views/collectives/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Nouvel atelier collectif')
  },
  {
    path: '/admin/collectives/:collectiveId/edit',
    name: 'Edit Collective',
    component: () => import('../views/collectives/New.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Modification d’atelier collectif')
  },
   //resssources (cv & cover letters)
   {
    path: '/admin/cv',
    name: 'Show CV',
    component: () => import('../views/ressources/All.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    props: {isCv: true}, 
    meta: meta('Les CV')
  },
  {
    path: '/admin/coverletters',
    name: 'Show Cover Letters',
    component: () => import('../views/ressources/All.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    props: {isCl: true}, 
    meta: meta('Les lettres de motivations')
  },
  //hidden offers
  {
    path: '/admin/hiddenOffers',
    name: 'Show Hidden Offers',
    component: () => import('../views/hiddenOffers/All.vue'),
    beforeEnter: myjobacademy ? ifAdmin : kick,
    meta: meta('Les offres cachées')
  },
    //qualities
  {
    path: '/admin/qualities/',
    name: 'EditQualities',
    component: () => import('../views/qualities/All.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //jobs
  {
    path: '/admin/jobs/',
    name: 'Show Jobs',
    component: () => import('../views/jobs/All.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
  {
    path: '/admin/jobs/new',
    name: 'New Job',
    component: () => import('../views/jobs/New.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
  {
    path: '/admin/jobs/:id/edit',
    name: 'Edit Job',
    component: () => import('../views/jobs/New.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //job cat
  {
    path: '/admin/job-categories',
    name: 'Show Job categories',
    component: () => import('../views/categories/All.vue'),
    props: {jobCat: true}, 
    beforeEnter: jobEden ? ifAdmin : kick
  },
  //sectors
  {
    path: '/admin/sectors/',
    name: 'Show sectors',
    component: () => import('../views/jobs/Sectors.vue'),
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //quality cat
  {
    path: '/admin/quality-categories',
    name: 'Show Qualities categories',
    component: () => import('../views/categories/All.vue'),
    props: {qualityCat: true}, 
    beforeEnter: jobEden ? ifAdmin : kick
  },
    //coaching cat
  {
    path: '/admin/coaching-categories/',
    name: 'Coaching Categories',
    component: () => import('../views/categories/All.vue'),
    props: {coachingCat: true}, 
    beforeEnter: ifAdmin
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
