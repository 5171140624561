import { partner } from '@/../config';


const state = {
  list: [
    {path: 'https://i.ibb.co/BBh8Jch/aftec.png', responsive: 'https://i.ibb.co/HFGJcbx/aftec-responsive.png', slug: 'aftec'},
    {path: 'https://i.ibb.co/D9FmhdZ/akimbo.png', responsive: 'https://i.ibb.co/Gpqyjw7/akimbo-responsive.png', slug: 'akimbo'}
  ]
} 

const getters = {
  getPartner: state => state.list.find(partnerItem => { return partnerItem.slug === partner })
}


export default {
    state,
    getters
}

